import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'

import type { SubtypeItem } from 'core/remodel/types/belongingSummary'
import { AssetType } from 'core/remodel/types/enums'
import { defaultPreferences } from 'core/remodel/types/user'
import { fetchPreferences, userQuery } from '@/api/AccountService'
import { commonQuery, fetchAttachmentIv, fetchThumbnailUrl } from '@/api/CommonService'
import { listPathnameMap, placeholderImageMap, subtypeOptionMap } from '@/constants/assets'
import { isValidSubtype } from '@/utils/checker'
import { cn } from '@/utils/classnames'
import { formatNumber } from '@/utils/formatter'
import { ImageSizes } from '@/utils/imageTools'
import { useAuthStore } from '@/store/authStore'
import { EncryptedImage } from '@/components/EncryptedImage'

export interface CollectableItemProps extends SubtypeItem {
  assetType: AssetType
  isEmptyState?: boolean
}

export function CollectableItem({
  assetType,
  label,
  itemNumber,
  value,
  mainImage,
  isEmptyState
}: CollectableItemProps) {
  const { t } = useTranslation()
  const database = useAuthStore((state) => state.database)
  const { data: imageUrl } = useSWR(
    mainImage && [commonQuery.thumbnailUrl, mainImage.assetId, mainImage.imageId, ImageSizes.Large],
    fetchThumbnailUrl(database!)
  )
  const { data: base64IV } = useSWR(
    mainImage && [commonQuery.attachmentIV, assetType, mainImage.assetId, mainImage.imageId],
    fetchAttachmentIv(database!)
  )
  const { data: preferences = defaultPreferences } = useSWR([userQuery.preferences], fetchPreferences(database!))

  const validSubtypeCheck = isValidSubtype(assetType, label)

  return (
    <Link
      href={{ pathname: listPathnameMap[assetType], query: validSubtypeCheck ? { subType: label } : null }}
      className={cn({ 'pointer-events-none': isEmptyState })}
      aria-disabled={isEmptyState}
      tabIndex={isEmptyState ? -1 : undefined}
    >
      <div
        className={cn('group relative h-[190px] w-full overflow-hidden rounded bg-grey/20', {
          'contrast-75 grayscale': isEmptyState
        })}
      >
        <EncryptedImage
          className={'object-contain transition-transform duration-500 ease-out group-hover:scale-110'}
          src={imageUrl}
          base64IV={base64IV}
          alt={label}
          fill={true}
          placeholder={placeholderImageMap[assetType]}
        />
        <div className={'absolute inset-x-0 bottom-0 space-y-1 bg-white/60 p-2 backdrop-blur-sm'}>
          <div className={'flex items-center justify-between'}>
            <span className={'text-sm text-text'}>
              {t(`AssetTypeOptions.${label}`, t(`${subtypeOptionMap[assetType]}${label}`, label))}
            </span>
            <div className={'rounded bg-background px-2 py-0.5 text-xs font-medium text-white'}>{itemNumber}</div>
          </div>
          <p className={'font-medium text-text'}>
            {`${value.currency} ${formatNumber(value.value, preferences.numberFormat)}`}
          </p>
        </div>
      </div>
    </Link>
  )
}
